<template>
  <div>
    <XNav :content="[{name:'About Us'}]" />
    <div class="main-info">
      <img src="@/assets/TKL-black.png" />
      <div class="group-text">
        <p>
          {{$t('aboutUs.groupTextP1')}}
        </p>
        <p>
          {{$t('aboutUs.groupTextP2')}}
        </p>
      </div>
    </div>
    <div class="founder-info">
      <div>
        <img class="picture" src="@/assets/about/ROBERT.png"/>
        <h3>Robert</h3>
        <p>
          {{$t('aboutUs.robertText')}}
        </p>
        <p>
          {{$t('aboutUs.robertClub')}}
        </p>
        <img class="favorite-club" src="@/assets/about/Borussia_Dortmund_logo.png"/>
      </div>
      <div>
        <img class="picture" src="@/assets/about/NADER.png"/>
        <h3>Nader</h3>
        <p>
          {{$t('aboutUs.naderText')}}
        </p>
        <p>
          {{$t('aboutUs.naderClub')}}
        </p>
        <img class="favorite-club" src="@/assets/about/Logo_Olympique_de_Marseille.png"/>
      </div>
      <div>
        <img class="picture" src="@/assets/about/KARIM.png"/>
        <h3>Karim</h3>
        <p>
          {{$t('aboutUs.karimText')}}
        </p>
        <p>
          {{$t('aboutUs.karimClub')}}
        </p>
        <img class="favorite-club" src="@/assets/about/Logo_FC_Liverpool.png"/>
      </div>
    </div>
  </div>
</template>

<script>
import XNav from "@/components/XNav.vue";
export default {
  components: {
    XNav
  },
  metaInfo() {
    return {
      title: this.$t("seo_metadata.about.title"),
      meta: [
        { vmid: "description", name: "description", content: this.$t("seo_metadata.about.description") },
        { vmid: "keywords", name: "keywords", content: this.$t("seo_metadata.keywords") },
        { vmid: "og:title", property: "og:title", content: this.$t("seo_metadata.about.title") },
        { vmid: "og:description", property: "og:description", content: this.$t("seo_metadata.about.description") },
        { vmid: "og:url", property: "og:url", content: "https://kingsleague.ca/about-us" },
        { vmid: "og:type", property: "og:type", content: "website"}
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
@import "@/theme.scss";
p {
    margin: 10px 0;
}
h3{
  @extend %font-bold;
  text-align: center;
  margin: 10px;
}
.main-info {
  display: flex;
  margin: 15px 0;
  justify-content: space-evenly;
 flex-wrap: wrap;
  align-items: center;
  img{
    flex: 1 1;
    margin: 20px;
    max-width: 400px;
    min-width: 300px;
    
  }
  p{
    flex: 1 1;
    max-width: 500px;
    min-width: 300px;
  }
}
.founder-info {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 30px 0;
  &>div{
    flex: 1 1;
    max-width: 300px;
    min-width: 200px;
    padding: 0 20px;
    .picture{
      display:block;
      height:auto;
      max-width: 200px;
      margin: 0 auto;
      background: gray;
    }
    .favorite-club{
      width: 60px;
      display:block;
      margin: 0 auto;
    }
  }
}
</style>